import React ,{useEffect} from 'react';
import '../css/base.css';
import '../css/vendor.css';
import '../css/main.css';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';




const values = [
    {
        title: "WE ARE YOUR PARTNER, NOT YOUR VENDOR",
        description:
            "It takes consistent collaboration to arrive at impactful solutions. We love working together with our clients to make that happen, leveraging their experiences with our expertise to create something worth sharing.",
    },
    {
        title: "WE CARE TOO MUCH TO PRETEND WE DON’T",
        description:
            "We simply can’t help ourselves from combing through the details. Transparency and honesty are equally important to us—we’ll let you know what you’re thinking, when we’re thinking it.",
    },

    {
        title: "WE UNDERSTAND BEFORE WE EXECUTE",
        description:
            "Measure twice, cut once. It works for carpenters, and it works for us. We strive to understand every side of the problem so we can develop accurate, holistic solutions.",
    },
    {
        title: "WE LEAD WITH INCLUSION",
        description:
            "We seek diverse perspectives and viewpoints to grow our understanding of the world around us. As we leverage and celebrate these personal experiences and cultures, we can more easily find powerful solutions to the problems that matter.",
    },
    {
        title: "WE CARE TOO MUCH TO PRETEND WE DON’T",
        description:
            "We simply can’t help ourselves from combing through the details. Transparency and honesty are equally important to us—we’ll let you know what you’re thinking, when we’re thinking it.",
    },
    {
        title: "NO EGO, ONLY IMPACT",
        description:
            "No task is beneath us. We seek to make a positive difference, no matter what that looks like or who gets the job done.",
    },
];

const Home = () => {
    useEffect(() => {
        const loadScript = (src) => {
            return new Promise((resolve, reject) => {
                const script = document.createElement('script');
                script.src = src;
                script.async = true;
                script.onload = resolve;
                script.onerror = reject;
                document.body.appendChild(script);
            });
        };

        const loadAllScripts = async () => {
            try {
                await loadScript('/js/jquery-3.2.1.min.js');
                await loadScript('/js/plugins.js');
                await loadScript('/js/main.js');
             
               
                console.log('Scripts loaded successfully!');
            } catch (error) {
                console.error('Error loading scripts:', error);
            }
        };

        loadAllScripts();
    }, []);

 
    return (
        <>
            <section
                id="home"
                className="s-home target-section"
                style={{
                    backgroundImage: "url('images/hero-bg.jpg')",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                }}
            >
                <div className="overlay"></div>
                <div className="shadow-overlay"></div>

                <div className="home-content">
                    <div className="row home-content__main">
                        <h3>Welcome to AdWitty</h3>
                        <h1>
                            We are a creative group <br />
                            of people who design <br />
                            influential brands and <br />
                            digital experiences.
                        </h1>
                        <div className="home-content__buttons">
                            <a href="#contact" className="smoothscroll btn btn--stroke">
                                Start a Project
                            </a>
                           

                        </div>
                    </div>

                    <div className="home-content__scroll">
                        <a href="#about" className="scroll-link smoothscroll">
                            <span>Scroll Down</span>
                        </a>
                    </div>

                  
                </div>

                <ul className="home-social">
                    <li>
                        <a href="#0">
                            <i className="fa fa-facebook" aria-hidden="true"></i>
                            <span>Facebook</span>
                        </a>
                    </li>
                    <li>
                        <a href="#0">
                            <i className="fa fa-twitter" aria-hidden="true"></i>
                            <span>Twitter</span>
                        </a>
                    </li>
                    <li>
                        <a href="#0">
                            <i className="fa fa-instagram" aria-hidden="true"></i>
                            <span>Instagram</span>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.linkedin.com/company/106020802/admin/dashboard/">
                            <i className="fa fa-linkedin" aria-hidden="true"></i>
                            <span>LinkedIn</span>
                        </a>
                    </li>
                </ul>
            </section>

            <section id='about' class="s-about">

                <div class="row section-header has-bottom-sep" data-aos="fade-up">
                    <div class="col-full">
                        <h3 class="subhead subhead--dark">Hello There</h3>
                        <h1 class="display-1 display-1--light">We Are AdWitty</h1>
                    </div>
                </div>

                <div class="row about-desc" data-aos="fade-up">
                    <div class="col-full">
                        <p>
                            AdWitty is the perfect platform for your business, offering customized, research-based, and growth-oriented solutions. Our team of experts will collaborate with you to understand your business goals and create a tailored strategy to achieve them. We will provide you with the tools, insights, and resources you need to succeed and drive your business forward
                        </p>
                    </div>
                </div>

                <div class="row about-stats stats block-1-4 block-m-1-2 block-mob-full" data-aos="fade-up">

                    <div class="col-block stats__col ">
                        <div class="stats__count">1</div>
                        <h5>Years of Experience</h5>
                    </div>
                    <div class="col-block stats__col">
                        <div class="stats__count">5</div>
                        <h5>Clients WE worked with</h5>
                    </div>
                    <div class="col-block stats__col">
                        <div class="stats__count">7</div>
                        <h5>Projects Completed</h5>
                    </div>
                    <div class="col-block stats__col">
                        <div class="stats__count">2</div>
                        <h5> Clients with 2x growth</h5>
                    </div>

                </div>

                <div class="about__line"></div>

            </section>
            <section id="services" className="s-services">
                <div className="row section-header has-bottom-sep" data-aos="fade-up">
                    <div className="col-full">
                        <h3 className="subhead">What We Do</h3>
                        <h1 className="display-2">
                            We’ve got everything you need to launch and grow your business
                        </h1>
                    </div>
                </div>

                <div className="row services-list block-1-2 block-tab-full">
                    <div className="col-block service-item" data-aos="fade-up">
                        <div className="service-icon">
                            <i className="icon-paint-brush"></i>
                        </div>
                        <div className="service-text">
                            <h3 className="h2">Brand Identity</h3>
                            <p>

                                Build a strong Brand Identity with a clear, consistent voice that resonates across all platforms.
                                From your logo and color scheme to your messaging and tone, we ensure your brand stands out in the crowded market.
                                By aligning every touchpoint with your core values and vision, we help you establish trust, foster loyalty,
                                and create a lasting impression on your audience. Let’s make your brand unforgettable.
                            </p>
                        </div>
                    </div>

                    <div className="col-block service-item" data-aos="fade-up">
                        <div className="service-icon">
                            <i className="fas fa-bolt"></i>
                        </div>
                        <div className="service-text">
                            <h3 className="h2">Search Engine Optimization(SEO)</h3>
                            <p>
                                Achieve Higher Visibility by improving your Google rankings to attract more visitors,
                                Better Traffic with cost-effective, optimized content that drives quality leads, and
                                gain a Competitive Edge by dominating your niche and outperforming competitors with a
                                strong SEO strategy.
                            </p>
                        </div>
                    </div>

                    <div className="col-block service-item" data-aos="fade-up">
                        <div className="service-icon">
                            <i className="fas fa-map-marker-alt"></i>
                        </div>
                        <div className="service-text">
                            <h3 className="h2">Google My Business</h3>
                            <p>
                                Google Search and Maps. An optimized GMB profile boosts local visibility
                                by securing a spot in the coveted "3-Pack," attracts nearby customers actively
                                searching for your services, and builds trust with glowing reviews and accurate business details.
                                It also enables you to engage customers through updates on offers, events, and news while providing
                                actionable insights into customer interactions to refine your marketing strategies and drive growth.
                            </p>
                        </div>
                    </div>

                    <div className="col-block service-item" data-aos="fade-up">
                        <div className="service-icon">
                            <i className="fas fa-chart-line"></i>
                        </div>
                        <div className="service-text">
                            <h3 className="h2">Performance Marketing</h3>
                            <p>
                                Maximize your brand's impact with performance marketing that delivers results.
                                Our targeted advertising ensures you reach the right audience at the right time,
                                while our ROI-driven strategies make every rupee count. With real-time metrics,
                                you can track, measure, and optimize ad performance to achieve your business goals
                                efficiently and effectively.
                            </p>
                        </div>
                    </div>

                    <div className="col-block service-item" data-aos="fade-up">
                        <div className="service-icon">
                            <i className="fas fa-share-alt"></i>


                        </div>
                        <div className="service-text">
                            <h3 className="h2">Social Media Management </h3>
                            <p>

                                Boost your brand’s online presence with tailored social media strategies designed to engage your audience, drive consistent growth, and deliver measurable results. We craft compelling posts, reels, and stories that reflect your brand’s voice, foster meaningful connections, and convert followers into loyal customers. Let us help you turn your social platforms into powerful tools for building relationships and achieving your business goals.
                            </p>
                        </div>
                    </div>

                    <div className="col-block service-item" data-aos="fade-up">
                        <div className="service-icon">
                            <i className="icon-lego-block"></i>
                        </div>
                        <div className="service-text">
                            <h3 className="h2">Web Services and Landing Page</h3>
                            <p>
                                We offer Professional Design with custom designs tailored to your brand's identity,
                                ensuring consistency and appeal. Our High-Converting Pages and Landing Pages are optimized for sales,
                                lead generation, and user engagement, turning visitors into loyal customers. Plus, with Mobile Responsiveness,
                                your web and landing pages provide a seamless user experience across all devices, from desktop to mobile,
                                enhancing accessibility and engagement.
                            </p>
                        </div>
                    </div>
                </div>
            </section>


          {/* <section id='works' class="s-works">
                <div class="intro-wrap">
                    <div class="row section-header has-bottom-sep light-sep" data-aos="fade-up">
                        <div class="col-full">
                            <h3 class="subhead">Recent Works</h3>
                            <h1 class="display-2 display-2--light">We love what we do, check out some of our latest works</h1>
                        </div>
                    </div>
                </div>

                <div class="row works-content">
                    <div class="col-full masonry-wrap">
                        <div class="masonry">

                            <div class="masonry__brick" data-aos="fade-up">
                                <div class="item-folio">
                                    <div class="item-folio__thumb">
                                        <a
                                            href="images/portfolio/gallery/g-shutterbug.jpg"
                                            className="thumb-link"
                                            title="Shutterbug"
                                            data-size="1050x700"
                                        >
                                            <img
                                                src={`${process.env.PUBLIC_URL}/Image/portfolio/lady-shutterbug.jpg`}

                                                alt="Shutterbug"
                                            />
                                        </a>

                                    </div>
                                    <div class="item-folio__text">
                                        <h3 class="item-folio__title">Shutterbug</h3>
                                        <p class="item-folio__cat">Branding</p>
                                    </div>
                                    <a href="https://www.behance.net/" class="item-folio__project-link" title="Project link">
                                        <i class="icon-link"></i>
                                    </a>
                                    <div class="item-folio__caption">
                                        <p>Vero molestiae sed aut natus excepturi. Et tempora numquam. Temporibus iusto quo.Unde dolorem corrupti neque nisi.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="masonry__brick" data-aos="fade-up">
                                <div class="item-folio">
                                    <div class="item-folio__thumb">
                                        <a href="images/portfolio/gallery/g-woodcraft.jpg" class="thumb-link" title="Woodcraft" data-size="1050x700">
                                            <img src={`${process.env.PUBLIC_URL}/Image/portfolio/woodcraft.jpg`} alt='' />

                                        </a>
                                    </div>
                                    <div class="item-folio__text">
                                        <h3 class="item-folio__title">Woodcraft</h3>
                                        <p class="item-folio__cat">Web Design</p>
                                    </div>
                                    <a href="https://www.behance.net/" class="item-folio__project-link" title="Project link">
                                        <i class="icon-link"></i>
                                    </a>
                                    <div class="item-folio__caption">
                                        <p>Vero molestiae sed aut natus excepturi. Et tempora numquam. Temporibus iusto quo.Unde dolorem corrupti neque nisi.</p>
                                    </div>
                                </div>
                            </div>


                            <div class="masonry__brick" data-aos="fade-up">
                                <div class="item-folio">
                                    <div class="item-folio__thumb">
                                        <a
                                            href="images/portfolio/gallery/g-beetle.jpg"
                                            className="thumb-link"
                                            title="The Beetle Car"
                                            data-size="1050x700"
                                        >
                                            <img
                                                src={`${process.env.PUBLIC_URL}/Image/portfolio/the-beetle.jpg`}
                                                alt="The Beetle Car"
                                            />
                                        </a>
                                    </div>
                                    <div class="item-folio__text">
                                        <h3 class="item-folio__title">The Beetle</h3>
                                        <p class="item-folio__cat">Web Development</p>
                                    </div>
                                    <a href="https://www.behance.net/" class="item-folio__project-link" title="Project link">
                                        <i class="icon-link"></i>
                                    </a>
                                    <div class="item-folio__caption">
                                        <p>Vero molestiae sed aut natus excepturi. Et tempora numquam. Temporibus iusto quo.Unde dolorem corrupti neque nisi.</p>
                                    </div>
                                </div>
                            </div>


                            <div class="masonry__brick" data-aos="fade-up">
                                <div class="item-folio">
                                    <div class="item-folio__thumb">
                                        <a href="images/portfolio/gallery/g-grow-green.jpg" class="thumb-link" title="Grow Green" data-size="1050x700">
                                            <img src={`${process.env.PUBLIC_URL}/Image/portfolio/row-green.jpg`}
                                                alt="" />
                                        </a>
                                    </div>
                                    <div class="item-folio__text">
                                        <h3 class="item-folio__title">Grow Green</h3>
                                        <p class="item-folio__cat">Branding</p>
                                    </div>
                                    <a href="https://www.behance.net/" class="item-folio__project-link" title="Project link">
                                        <i class="icon-link"></i>
                                    </a>
                                    <div class="item-folio__caption">
                                        <p>Vero molestiae sed aut natus excepturi. Et tempora numquam. Temporibus iusto quo.Unde dolorem corrupti neque nisi.</p>
                                    </div>
                                </div>
                            </div>


                            <div class="masonry__brick" data-aos="fade-up">
                                <div class="item-folio">
                                    <div class="item-folio__thumb">
                                        <a href="images/portfolio/gallery/g-guitarist.jpg" class="thumb-link" title="Guitarist" data-size="1050x700">
                                            <img src={`${process.env.PUBLIC_URL}/Image/portfolio/guitarist.jpg`} alt="" />
                                        </a>
                                    </div>
                                    <div class="item-folio__text">
                                        <h3 class="item-folio__title">Guitarist</h3>
                                        <p class="item-folio__cat">Web Design</p>
                                    </div>
                                    <a href="https://www.behance.net/" class="item-folio__project-link" title="Project link">
                                        <i class="icon-link"></i>
                                    </a>
                                    <div class="item-folio__caption">
                                        <p>Vero molestiae sed aut natus excepturi. Et tempora numquam. Temporibus iusto quo.Unde dolorem corrupti neque nisi.</p>
                                    </div>
                                </div>
                            </div>


                            <div class="masonry__brick" data-aos="fade-up">
                                <div class="item-folio">
                                    <div class="item-folio__thumb">
                                        <a href="images/portfolio/gallery/g-palmeira.jpg" class="thumb-link" title="Palmeira" data-size="1050x700">
                                            <img src={`${process.env.PUBLIC_URL}/Image/portfolio/palmeira.jpg`}
                                                alt="" />
                                        </a>
                                    </div>
                                    <div class="item-folio__text">
                                        <h3 class="item-folio__title">Palmeira</h3>
                                        <p class="item-folio__cat">Web Design</p>
                                    </div>
                                    <a href="https://www.behance.net/" class="item-folio__project-link" title="Project link">
                                        <i class="icon-link"></i>
                                    </a>
                                    <div class="item-folio__caption">
                                        <p>Vero molestiae sed aut natus excepturi. Et tempora numquam. Temporibus iusto quo.Unde dolorem corrupti neque nisi.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
           */}


            <section id="clients" class="s-clients">
                <div class="row section-header" data-aos="fade-up">
                    <div class="col-full">
                        <h3 class="subhead">Our Clients</h3>
                        <h1 class="display-2">Adwitty has been honored with our  clients</h1>
                    </div>
                </div>

                <div class="row clients-outer" data-aos="fade-up">
                    <div class="col-full">
                        <div class="clients">
                            <div className='values'>
                                <div className="values-grid">
                                    {values.map((value, index) => (
                                        <div className="value-card" key={index}>
                                            <h3>{value.title}</h3>
                                            <p>{value.description}</p>
                                        </div>
                                    ))}
                                </div>

                                <div className='value-image'>
                                    {/* <img src={`${process.env.PUBLIC_URL}/Image/values.jpg`}
        alt='value-image' /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>

            <section id="contact" className="s-contact">
                <div className="overlay"></div>


                <div className="row section-header" data-aos="fade-up">
                    <div className="col-full">
                        <h3 className="subhead">Contact Us</h3>
                        <h1 className="display-2 display-2--light">
                            Reach out for a new project or just say hello
                        </h1>
                    </div>
                </div>

                <div className="row contact-content" data-aos="fade-up">
                    <div className="contact-primary">
                        <h3 className="h6">Send Us A Message</h3>
                        <Card sx={{ maxWidth: 345 }}>
                                <CardMedia
                                    sx={{ height: 140 }}
                               
                                    image={`${process.env.PUBLIC_URL}/Image/contact.jpg`}
                                    title="Contact us"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                        Reach Us!
                                    </Typography>
                                    <Typography variant="body2" sx={{ color: 'text.secondary',fontSize:'14px' }}>
                                      Get in touch with us and we will get back to you as soon as possible.
                                      Please fill in the form below.
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                <Button style={{ fontSize: "14px" }}>
      <a  style={{ color:"blue" }}href="/contact">Fill the form</a>
    </Button>
                                   
                                </CardActions>
                            </Card>
                    </div>

                    <div className="contact-secondary">
                        <div className="contact-info">
                            <h3 className="h6 hide-on-fullwidth">Contact Info</h3>
                          

                            <div className="cinfo">
                                <h5>Email Us At</h5>
                                <p>
                                    addwittty@gmail.com
                                    
                                </p>
                            </div>

                            <div className="cinfo">
                                <h5>Call Us At</h5>
                                <p>

                                    Mobile: (+91) 8591255620
                                   
                                </p>
                            </div>

                            <ul className="contact-social">
                                <li>
                                    <a href="#">
                                        <i className="fa fa-facebook" aria-hidden="true"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i className="fa fa-twitter" aria-hidden="true"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i className="fa fa-instagram" aria-hidden="true"></i>
                                    </a>
                                </li>
                                <li>

                                </li>
                                <li>
                                    <a href="https://www.linkedin.com/company/106020802/admin/dashboard/">
                                        <i className="fa fa-linkedin" aria-hidden="true"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>



            <button className="help-button">
                <a
                    href="https://wa.me/918591255620"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}
                >
                    <i className="fab fa-whatsapp" style={{ marginRight: '8px', fontSize: '1.2rem' }}></i>
                    Hi, how can I help?
                </a>
            </button>
        </>

    );
};

export default Home;
