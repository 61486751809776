import React from 'react'
import PerformanceMarketing from '../Component/Services/PerformanceMarketing'
import Header from '../Component/head'
import Footer from '../Component/Foot'

const Performance_Marketing = () => {
  return (
   <div>
    <div className='header'>
    <Header />
  </div>
  <PerformanceMarketing/>
   <Footer />
   </div>
  )
}

export default Performance_Marketing;
