// About.js
import React from 'react';
import './about.css';
import { Link } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import '../assets/css/fontawesome.css';
import '../assets/css/templatemo-tale-seo-agency.css';




// Custom TabPanel Component
const CustomTabPanel = ({ children, value, index }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
  >
    {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
  </div>
);

const About = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
    <div>
      {/* Banner Section */}
      <div className="banner-about" style={{ width: '100%', height: 'auto' }}>
        <img
          src={`${process.env.PUBLIC_URL}/Image/about.jpg`}
          alt="banner"
          style={{ width: '100%', height: 'auto', objectFit: 'cover' }}
        />
      </div>

      {/* Page Heading Section */}
      <div className="page-heading" style={{ padding: '50px 0' }}>
        <div className="container">
          <div className="row">
          
              <div className="caption header-text">
                <h6>AD WITTY</h6>
                <div className="line-dec"></div>
                <h4>
                  Discover More <em>About Us</em>
                </h4>
                <p>
                  Welcome to AdWitty, where innovation meets strategy. We don’t
                  just offer solutions—we craft bespoke, data-driven strategies
                  that evolve with your business. Our expert team becomes an
                  extension of your own, working hand-in-hand to understand your
                  goals and unlock your full potential. With AdWitty, success
                  isn't just a destination, but a journey of continuous growth
                  and transformation.
                </p>
                <div className="main-button">
                  <Link to="/contact">Discover More</Link>
                </div>
                
              </div>
       
            <div className="col-lg-5 align-self-center">
              <img
                src="assets/images/about-us-image.jpg"
                alt="About Us"
                style={{ width: '100%', borderRadius: '10px' }}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Video Information Section */}
      <div className="custom-video-info-section" style={{ padding: '50px 0' }}>
  <div className="custom-responsive-container">
    <div className="custom-flex-row-responsive" id="custom-video-box">
      <div className="custom-video-thumb" style={{ position: 'relative' }}>
        <img
          src="assets/images/video-thumb.jpg"
          alt="Video Thumbnail"
          style={{ width: '100%', borderRadius: '10px' }}
        />
        <a
          href="http://youtube.com"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            fontSize: '50px',
            color: '#fff',
            backgroundColor: '#007bff',
            borderRadius: '50%',
            padding: '15px',
          }}
        >
          <i className="fa fa-play"></i>
        </a>
      </div>
      <div className='video-content'>
      <div className="custom-section-heading" id="custom-section-video">
        <h2>Detailed Information On What We Do &amp; Who We Are</h2>
        <div className="custom-line-dec"></div>
        <p>
          We provide various services based on your demand and business needs.
          Our team is always ready to help you with any questions or concerns
          you may have.
        </p>
      </div>
      <div className="custom-skills">
        <SkillSlide title="SEO Marketing" percentage="90%" />
        <SkillSlide title="Digital Marketing" percentage="80%" />
        <SkillSlide title="Social Media Management" percentage="95%" />
        <SkillSlide title="Website Development and Services" percentage="95%" />
        <SkillSlide title="Landing Page on Demand" percentage="95%" />
        <SkillSlide title="Google Ads" percentage="95%" />
      </div>
      </div>
    </div>
  </div>
</div>


      {/* Happy Clients Section */}
      <div className="happy-clients section" style={{ padding: '50px 0' }}>
        <div className="container">
          <div className="section-heading">
            <h2 className='h2' style={{width:"100%"}}>
              Our 4 Steps <em>To Success</em> &amp; <span>Happy Clients</span>
            </h2>
            <div className="line-dec"></div>
            <p style={{width:"100%",'max-width':'100vw'}}>
              Our steps are quite comprehensive and smoothly backed by data and
              research.
            </p>
          </div>
          {/* Tabs Section */}
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="success steps"
              >
                <Tab label="Project Introduction" />
                <Tab label="Tech Assistance" />
                <Tab label="Advertising & Marketing" />
                <Tab label="Data Analysis" />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              Content for Project Introduction
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              Content for Tech Assistance
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              Content for Advertising & Marketing
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
              Content for Data Analysis
            </CustomTabPanel>
          </Box>
        </div>
      </div>
    </div>
   
  </>
  );
};

// SkillSlide Component
const SkillSlide = ({ title, percentage }) => (
  <div style={{ marginBottom: '20px' }}>
    <h6>{title}</h6>
    <div
      style={{
        width: '100%',
        backgroundColor: '#f0f0f0',
        borderRadius: '5px',
        overflow: 'hidden',
      }}
    >
      <div
        style={{
          width: percentage,
          height: '10px',
          backgroundColor: '#007bff',
        }}
      ></div>
    </div>
  </div>
);

export default About;
