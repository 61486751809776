import React from 'react'

import Head from '../Component/head';
import Home from '../Component/Home';
import Footer from '../Component/Foot';
import './page.css';

const HomePage = () => {
  return (

    <div>
      <div className='header'>
        <Head/>
        </div>
        <Home/>
        <Footer/>
      
    </div>
  )
}

export default HomePage
